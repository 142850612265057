#radio {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    /* margin-top: 5px; */
    /* margin-left: 7px; */
  }
  #radio input {
    position: absolute;
    left: -9999px;
  }
  #radio input:checked + span:before {
    box-shadow: inset 0 0 0 0.4375em #CDA950;
    border-width: 0px;
  }
  #radio span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
  }
  #radio span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #1B1B23;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border-color: #85878D;
    border-width: 1px;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #1B1B23;
  }
  