.ql-editor.ql-blank::before {
  color: white;
  opacity: 0.5;
  border-radius: 2px;
}
.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #1a1a22 !important;
}
.ql-toolbar.ql-snow {
  position: sticky;
  top: 0;
  z-index: 1000;
  border: 1px solid transparent !important;
  border-bottom: 2px solid #0f0f14 !important;
}
.ql-container.ql-snow {
  border: 1px solid transparent !important;
}
/* .ql-toolbar .ql-formats button {
  background-image: none !important;
  width: auto;
  min-width: 30px;
  height: auto;
  padding: 0px 5px;
  font-family: Arial, sans-serif;
  color: white;
  font-size: 12px;
  text-align: center;
}


.ql-bold::before {
  content: '\f032';
  font-family: 'FontAwesome';
}
.ql-italic::before {
  content: '\f033';
  font-family: 'FontAwesome';
}
.ql-underline::before {
  content: '\f0cd';
  font-family: 'FontAwesome';
}
.ql-strike::before {
  content: '\f0cc';
  font-family: 'FontAwesome';
}
.ql-blockquote::before {
  content: '\201C';
  font-family: 'Segoe UI Symbol';
}

.ql-code-block::before {
  content: '\f121';
  font-family: 'FontAwesome';
}
.ql-list[value='ordered']::before {
  content: '\f0cb';
  font-family: 'FontAwesome';
}
.ql-list[value='bullet']::before {
  content: '\f0ca';
  font-family: 'FontAwesome';
}
.ql-link::before {
  content: '\1F517';
  font-family: 'Segoe UI Symbol';
}
.ql-image::before {
  content: '\1F5BC';
}
.ql-video::before {
  content: '\1F4F9';
}
.ql-clean::before {
  content: '\274C';
}
.ql-align[value='']::before {
  content: '\f036';
  font-family: 'FontAwesome';
}
.ql-align[value='center']::before {
  content: '\f037';
  font-family: 'FontAwesome';
}
.ql-align[value='right']::before {
  content: '\f038';
  font-family: 'FontAwesome';
}
.ql-align[value='justify']::before {
  content: '\f039';
  font-family: 'FontAwesome';
}

.ql-toolbar .ql-header[value='1']::before {
  content: 'H1';
}

.ql-toolbar .ql-header[value='2']::before {
  content: 'H2';
}
*/
/* Default (not active) */
.ql-toolbar .ql-header[value='3']::before {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path fill="%23444444" d="M16.5 12.7c0-.8-.5-1.4-1.3-1.7.5-.3.9-.8.9-1.5 0-1.1-1-1.8-2.5-1.8-1.3 0-2.3.4-3 .8-.3.2-.3.6-.1.8l.3.4c.2.3.5.4.8.2.5-.3 1.1-.6 1.8-.6.7 0 1.1.3 1.1.8s-.5.7-1.2.7H13c-.3 0-.5.2-.5.5v.5c0 .3.2.5.5.5h.1c.9 0 1.4.4 1.4.9 0 .5-.5.8-1.1.8-.8 0-1.4-.4-2-.8-.3-.2-.6 0-.8.2l-.3.5c-.2.2-.2.5.1.7.7.5 1.6 1 3 1 1.8 0 3-1 3-2.3zM7 3c-.5 0-1 .5-1 1v4H2V4c0-.5-.5-1-1-1s-1 .5-1 1v10c0 .5.5 1 1 1s1-.5 1-1v-4h4v4c0 .5.5 1 1 1s1-.5 1-1V4c0-.5-.5-1-1-1z"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  content: '';
  color: #444444;
}

/* Active (when ql-active is present) */
.ql-toolbar .ql-header[value='3'].ql-active::before {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path fill="%232666CC" d="M16.5 12.7c0-.8-.5-1.4-1.3-1.7.5-.3.9-.8.9-1.5 0-1.1-1-1.8-2.5-1.8-1.3 0-2.3.4-3 .8-.3.2-.3.6-.1.8l.3.4c.2.3.5.4.8.2.5-.3 1.1-.6 1.8-.6.7 0 1.1.3 1.1.8s-.5.7-1.2.7H13c-.3 0-.5.2-.5.5v.5c0 .3.2.5.5.5h.1c.9 0 1.4.4 1.4.9 0 .5-.5.8-1.1.8-.8 0-1.4-.4-2-.8-.3-.2-.6 0-.8.2l-.3.5c-.2.2-.2.5.1.7.7.5 1.6 1 3 1 1.8 0 3-1 3-2.3zM7 3c-.5 0-1 .5-1 1v4H2V4c0-.5-.5-1-1-1s-1 .5-1 1v10c0 .5.5 1 1 1s1-.5 1-1v-4h4v4c0 .5.5 1 1 1s1-.5 1-1V4c0-.5-.5-1-1-1z"></path></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  content: '';
  color: #2666cc;
}
