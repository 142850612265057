@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --yelllow: #cda950;
  --white: #ffffff;
  --red: #ea3943;
}

.breadCrumbsYellowText {
  font-size: 11px;
  font-family: 'Inter-Regular';
  color: var(--yelllow);
}

.interRegular_11 {
  font-size: 11px;
  font-family: 'Inter-Regular';
}

.interRegular_13 {
  font-size: 13px;
  font-family: 'Inter-Regular';
}

.errorText_12 {
  font-size: 12px;
  font-family: 'Inter-Regular';
  color: var(--red);
}

.dropdown {
  height: 25px;
  margin-top: 10px !important;
}

body {
  background-color: #0f0f14;
  overscroll-behavior-y: none;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}
img {
  display: unset !important;
}
.logo-wrapper {
  /* width: 50%;
  height: 50%;*/
  /* width: 99vw; */
  text-align: center;
}

.logo-wrapper img {
  width: 120px;
}
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}
.css-1rg7osp.TableCell-cell:first-of-type {
  padding-left: 16px !important;
}

.tagsInput input::placeholder {
  color: var(--white) !important;
  font-weight: normal;
  font-family: 'Inter';
}
.tagsInput input:focus {
  border-radius: 5px !important;
  border: none;
  border-color: none !important;
  box-shadow: none !important;
}
#contentEditableArea ul {
  list-style-type: disc !important;
  margin-left: 30px !important;
}
#contentEditableArea a {
  color: var(--yelllow);
}
#contentEditableArea img {
  border-radius: 8px !important;
}
.campus-preview-page ul {
  list-style-type: disc !important;
  margin-left: 30px !important;
}
.campus-preview-page a {
  color: var(--yelllow) !important;
}

.hideIcon::-webkit-calendar-picker-indicator {
  margin-left: -20px;
}

/* Styles For Google AutoComplete Field */
.pac-container {
  z-index: 99999 !important;
  background-color: #1b1b23 !important;
  color: var(--white) !important;
}

.pac-container .pac-item {
  color: var(--white) !important;
}
.pac-container .pac-item:hover {
  background-color: #7f8492 !important;
  color: var(--white) !important;
}
.pac-container .pac-item .pac-item-query {
  color: var(--white) !important;
}
.pac-container .pac-item-selected {
  background-color: #7f8492 !important;
  color: var(--white) !important;
}
#autocomplete-google-map input::placeholder {
  color: transparent !important;
}

/* Custom Classes*/
@layer utilities {
  .border-gray {
    border: 1px solid #7f8492;
  }
}
#autocomplete-google-map input::placeholder {
  color: transparent !important;
}
