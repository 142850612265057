body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* width */
::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(133, 135, 141);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(194, 189, 189, 1);
}
